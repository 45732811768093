import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  private api = environment.apiUrl + 'api'; //API HERE
  //const AUTH_API = '';

  login(credentials): Observable<any> {
    return this.http.post(`${this.api}/authentication/login.php`, {
      email: credentials.email,
      password: credentials.password
    }, httpOptions);
  }

  register(user): Observable<any> {
    return this.http.post(`${this.api}/authentication/create_user.php`, {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      password: user.password
    }, httpOptions);
  }
  initAuth(mode: string): Observable<any>{
    return this.http.get(`${this.api}/setup/setup.php?x=get_setup&mode=${mode}`, httpOptions);
  }
  mediaLibAuth(mtoken: string,token:string): Observable<any>{
    let storage = new Array();
    storage['jwt'] = '';
    if(localStorage.getItem("medialibrary-auth-user-"+token)){
       storage =JSON.parse(localStorage.getItem("medialibrary-auth-user-"+token));
    }
    return this.http.post(`${this.api}/setup/setup.php?x=medialib`, {
      token: mtoken,
      jwt: storage['jwt']
    }, httpOptions);
  }
}
