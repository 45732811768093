<!-- <img class="ui tiny image"
     *ngIf="potter.thumbnails && potter.thumbnails[0] && potter.thumbnails[0].url"
     [src]="potter.thumbnails[0].url"> -->
<div *ngIf="screenwidth > 512; else mobile">
  <!-- <div class="header">{{ question.id }}</div> -->
  <!-- <div *ngIf="question.location" class="description">{{ question.location }}</div> -->
  <div class="metadata" [ngClass]="{ 'question-highlight': highlight }">
    <!--<span *ngFor="let author of potter.authors; last as l">
          {{ author }}<span *ngIf="!l">, </span>
        </span>-->
    <div class="hr_vod_playbutton">
      <i
        *ngIf="!highlight && player.paused()"
        (click)="player.play() && clickQuestion()"
        class="ff playbutton"
      ></i>
      <i
        *ngIf="!highlight && !player.paused()"
        (click)="player.play() && clickQuestion()"
        class="ff playbutton"
      ></i>
      <i
        *ngIf="highlight && !player.paused()"
        (click)="player.pause()"
        class="ff pausebutton"
      ></i>
      <i
        *ngIf="highlight && player.paused()"
        (click)="player.play()"
        class="ff playbutton"
      ></i>
    </div>
    <div class="hr_vod_content" (click)="clickQuestion()">
      <div class="hr_vod_question" style="white-space: pre-wrap">{{questionTxt?.trim()}}</div>
      <!--Important: NO Leading Spaces!-->
      <div class="hr_vod_TC">
        {{ question.tc_start }}
      </div>
    </div>

    <!--
    TC Start: {{ question.tc_start }}
    <br />
    TC Ende: {{ question.tc_end }} -->
  </div>
</div>
<ng-template #mobile>
  <!-- <div class="header">{{ question.id }}</div> -->
  <!-- <div *ngIf="question.location" class="description">{{ question.location }}</div> -->
  <div class="metadata">
    <!--<span *ngFor="let author of potter.authors; last as l">
            {{ author }}<span *ngIf="!l">, </span>
          </span>-->
    <div class="hr_vod_content">
      <div class="hr_vod_question" style="white-space: pre-wrap">{{questionTxt?.trim()}}</div>
      <div class="hr_vod_mobile_footer">
        <div class="hr_vod_TC">
          {{ question.tc_start }}
        </div>
        <div class="hr_vod_playbutton">
          <i
            *ngIf="!highlight && player.paused()"
            (click)="player.play() && clickQuestion()"
            class="ff playbutton"
          ></i>
          <i
            *ngIf="!highlight && !player.paused()"
            (click)="player.play() && clickQuestion()"
            class="ff playbutton"
          ></i>
          <i
            *ngIf="highlight && !player.paused()"
            (click)="player.pause()"
            class="ff pausebutton"
          ></i>
          <i
            *ngIf="highlight && player.paused()"
            (click)="player.play()"
            class="ff playbutton"
          ></i>
        </div>
      </div>
    </div>

    <!--
      TC Start: {{ question.tc_start }}
      <br />
      TC Ende: {{ question.tc_end }} -->
  </div>
</ng-template>
