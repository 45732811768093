import { Eventstatus } from '../shared/eventstatus';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { LogService } from '../shared/log.service';
import { Slide } from '../shared/slide';
import { SlideStoreService } from '../shared/slide-store.service';
import { VideoplayerComponent } from '../videoplayer/videoplayer.component';
import { PlayerStoreService } from '../shared/player-store.service';

@Component({
  selector: 'lse-slideflow-list',
  templateUrl: './slideflow-list.component.html',
  styleUrls: ['./slideflow-list.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1.5s ease-in', style({ height: 0, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: 0, opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          overflow: 'hidden',
          height: '*'
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '0px'
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ])
  ]
})
export class SlideflowListComponent implements OnInit {
  @ViewChild('slideHistoryChild', { read: ElementRef })
  public slideHistoryChild: ElementRef<any>;
  @ViewChild('slideHistoryContent', { read: ElementRef })
  public slideHistoryContent: ElementRef<any>;
  @ViewChild('slideDiv') slideDiv: ElementRef;
  @Input() playlist: Slide[];
  @Input() currentSlide: Slide;
  @Input() phase: string;
  @Input() eStatus: Eventstatus;
  @Input() eLangGlob: any;
  @Input() slideIsCustom: any;

  @Output() playAtEvent = new EventEmitter();
  @Output() showElement = new EventEmitter();
  @Output() myReturnToLiveSlide = new EventEmitter();

  // playlist: Slide[];
  holdMyTime: any;
  path: string;
  slideIndex: number;
  hasScrolled = false;
  scrollMax = 0;
  showSlideFlow = 'in';
  screenwidth = document.body.offsetWidth;

  constructor(
    private es: EventconfigStoreService,
    private ss: SlideStoreService,
    private logger: LogService,
    private playerComponent: VideoplayerComponent,
    private playerStoreService: PlayerStoreService
  ) {
  }

  ngOnInit(): void {
    //this.retrieveSlideFlow();
    this.path = this.ss.getSlidePath();
    setTimeout(() => {
      this.scrollToMid();
    }, 100);
    // if (this.es.eState) this.playlist.reverse();
    this.checkNewSlide();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
  }

  onshowElement(e: string) {
    this.showElement.emit(e);
  }

  returnToLiveSlide() {
    this.myReturnToLiveSlide.emit();
  }

  scrollToMid() {
    console.log('scrollToMid');
    if (this.phase === 'post-live') {
      setInterval(() => {
        let oldIndex = this.slideIndex;
        this.slideIndex = this.playlist.findIndex(
          (x) => x.uid === this.currentSlide.uid
        );
        if (oldIndex !== this.slideIndex) {
          if (!this.hasScrolled) {
            if (this.slideIndex > 1) {
              const e =
                this.slideIndex *
                (this.slideHistoryContent.nativeElement.scrollWidth /
                  this.playlist.length) -
                (this.slideHistoryContent.nativeElement.offsetWidth / 2 - 70);
              //this.logger.log('ScrollTo: ' + this.slideDiv.nativeElement.offsetWidth);
              this.slideHistoryContent.nativeElement.scrollTo({
                left: e,
                behavior: 'smooth'
              });
            }
          } else {
            this.hasScrolled = false;
          }
        }
      }, 500);
    } else {
      this.scrollMax =
        this.slideHistoryContent.nativeElement.scrollWidth -
        this.slideHistoryContent.nativeElement.clientWidth;
      this.logger.log(
        'Scroll Left: ' + this.slideHistoryContent.nativeElement.scrollWidth
      );
      this.slideHistoryContent.nativeElement.scrollTo({
        left: this.scrollMax,
        behavior: 'smooth'
      });

    }
  }

  // getLazy() {
  //   const lazyImages = [].slice.call(
  //     document.querySelectorAll('.lazy-loaded-image.lazy')
  //   );
  //   const lazyImageObserver = new IntersectionObserver(function(entries,
  //     observer
  //   ) {
  //     entries.forEach(function(entry) {
  //       if (entry.isIntersecting) {
  //         let lazyImage = entry.target;
  //         lazyImage.setAttribute('src', lazyImage.getAttribute('data-src'));
  //         // lazyImage.src = lazyImage.dataset.src;
  //         lazyImage.classList.remove('lazy');
  //         lazyImageObserver.unobserve(lazyImage);
  //       }
  //     });
  //   });
  //   lazyImages.forEach(function(lazyImage) {
  //     lazyImageObserver.observe(lazyImage);
  //   });
  // }

  checkNewSlide() {
    console.log('checkNewSlide');
    setInterval(() => {
      //this.logger.log('SlideScroll: ' + this.hasScrolled);
      // this.getLazy();
      if (
        !this.hasScrolled &&
        this.scrollMax <
        this.slideHistoryContent.nativeElement.scrollWidth -
        this.slideHistoryContent.nativeElement.clientWidth
      ) {
        console.log('checkNewSlide');
        this.scrollToMid();
        this.scrollMax =
          this.slideHistoryContent.nativeElement.scrollWidth -
          this.slideHistoryContent.nativeElement.clientWidth;
      }
    }, 2000);
  }

  // retrieveSlideFlow() {
  // }

  handleEvent(value) {
    this.holdMyTime = value;
    this.playAtEvent.emit(value);
    this.logger.log(value);
  }

  trackSlideflow(index: number, slide: Slide) {
    return slide.uid;
  }

  scrollToCenter(value) {
    setTimeout(() => {
      const i = this.playlist.findIndex((x) => x.uid === this.currentSlide.uid);
      this.logger.log('Scroll to Center ' + i);
      if (i > 1) {
        const e =
          i *
          (this.slideHistoryContent.nativeElement.scrollWidth /
            this.playlist.length) -
          (this.slideHistoryContent.nativeElement.offsetWidth / 2 - 70);
        // this.logger.log('ScrollTo: ' + this.slideDiv.nativeElement.offsetWidth);
        this.slideHistoryContent.nativeElement.scrollTo({
          left: e,
          behavior: 'smooth'
        });
      }
    }, 200);
  }

  playAtFunction(time) {
    console.log('triggered playatfunc', time);
    this.playAtEvent.emit(time);

  }

  scroll(direction: string) {
    console.log('scroll() direction:', direction);
    this.logger.log('Direction: ' + direction);
    if (direction === 'left') {
      this.hasScrolled = true;
      this.slideHistoryContent.nativeElement.scrollTo({
        left: this.slideHistoryContent.nativeElement.scrollLeft - 150,
        behavior: 'smooth'
      });
    } else if (direction === 'right') {
      this.slideHistoryContent.nativeElement.scrollTo({
        left: this.slideHistoryContent.nativeElement.scrollLeft + 150,
        behavior: 'smooth'
      });
      if (
        this.slideHistoryContent.nativeElement.scrollLeft ===
        this.slideHistoryContent.nativeElement.scrollWidth -
        this.slideHistoryContent.nativeElement.clientWidth
      ) {
        this.hasScrolled = false;
      }
      if (this.phase === 'post-live') {
        this.hasScrolled = true;
      }
    }
  }

  toggleSlideflow() {
    this.showSlideFlow = this.showSlideFlow === 'out' ? 'in' : 'out';
  }
}
