import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lse-user-see-question',
  templateUrl: './user-see-question.component.html',
  styleUrls: ['./user-see-question.component.css']
})
export class UserSeeQuestionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
