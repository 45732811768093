import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class SlideStoreService {
  slidePath: string;

  constructor(private logger: LogService) {}

  setSlidePath(path: string, lang: string) {
    // this.slidePath = environment.apiUrl + 'event/' + path + '/jpg_' + lang + '/';
    this.slidePath = environment.apiUrl + environment.eventPrefix + path + '/jpg_' + lang + '/';
    this.logger.log('Slide-Path: ' + this.slidePath);
  }
  getSlidePath() {
    this.logger.log('Get: ' + this.slidePath);
    return this.slidePath;
  }
}
