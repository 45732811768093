import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lse-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})
export class DescriptionComponent implements OnInit{
  @Input() description: string;
  constructor() {}

  descriptionText: string = '';
  ngOnInit() {
        this.descriptionText = this.description;
  }

}
