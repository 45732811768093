export class LangGlobalsDE{
  public static translateSendBtn = 'Senden';
  public static translateMsgLabel = 'Nachricht';
  public static translateAdditionalLabel = 'Firma*';
  public static translateNameLabel = 'Name*';
  public static translatePolicy = '* Bitte beachten Sie unsere Datenschutzbestimmungen';
  public static translateUaqHeadlineLabel = 'Stellen Sie hier Ihre Frage';
  public static translateDatapolicy = 'Datenschutz';
  public static translateImprint = 'Impressum';
  public static translateTechInfo = 'Technik Info';
  public static translateSendmail = 'Im Falle einer Störung senden Sie bitte eine Nachricht an';
  public static translateBtnPresentation = 'Präsentation';
  public static translateBtnQuestions = 'Fragen';
  public static translateCurSlide = 'Aktuelle Folie';
  public static translateSlide = 'Folien';
  public static translateQuestions = 'Fragen';
  public static translateQuestion = 'Frage';
  public static translateQuestionsFixed = 'Fragen';
  public static translateTotal = 'total';
  public static translateTo = 'bis';
  public static translateParticipants = 'Teilnehmende';
  public static translateSlideHistory = 'Folienverlauf';
  public static translateYour = 'Ihre';
  public static translateChapter = 'Kapitel';
  public static translateqSuccess = 'Ihr Frage wurde erfolgreich übermittelt';
  public static translateqError = 'Bitte geben Sie einen Fragetext ein';
  public static translateUsername = 'Benutzername';
  public static translatePassword = 'Passwort';
  public static translatePWRequest = 'Um am Live-Stream teilzunehmen loggen Sie sich bitte mit Ihren Login-Daten ein';
  public static translatePWSuccess = 'Login erfolgreich';
  public static translatePWError = 'Login fehlgeschlagen';
  public static translateNoPW = 'Bitte geben Sie ein Passwort ein';
  public static translatePWtooShort = 'Das eingegebene Passwort muss mindestens sechs Zeichen lang sein';
  public static translateNoUsername = 'Bitte geben Sie einen Benutzernamen ein';
  public static translateBtnLogin = 'Anmelden';
  public static translateBtnLogout = 'Abmelden';
  public static translateLoggedInAs = 'Angemeldet als';
  public static translateEmailSubject = 'Live-Streaming - Meldung einer Störung';
  public static translateStrawpollTitle = 'Abstimmung';
  public static translateStrawpollResults = 'Abstimmungsergebnis';
  public static translateStrawpollYourVote = 'Vielen Dank für Ihre Stimme. Sie haben für';
  public static translateStrawpollVoted = ' abgestimmt.';
  public static translateStrawpollResultDefer = 'Die Ergebnisse werden demnächst veröffentlicht';
  public static translateStrawpollClick = 'Bestätigen Sie Ihre Wahl';
  public static translateStrawpollFailed = 'Abstimmung fehlgeschlagen. Bitte wählen Sie eine der Optionen aus!';
  public static translateChoice = 'Ihre Wahl';
  public static translateResult = 'Ergebnisse';
  public static translateVoteNow = 'Stimmen Sie ab!';
  public static translateVoteInformation = 'Eingegangene Fragen';
  public static translateVoteCollapse = 'Fragenabstimmung';
  public static translateMediaLibraryShort = 'Mediathek';
  public static translateMediaLibraryLong = 'Dashboard der Mediathek';
  public static translateSyncDesctiption = 'Klicken, um die manuelle Navigation durch die Fragen/Kapitel zu beenden. Diese folgen dann wieder automatisch dem abgespielten Video.';
  public static translateFurtherEvents = 'weitere Events';
  public static translateEventDescription ="Eventbeschreibung"
  public static translatePolicyName = '';
  public static translatePolicyAdditional = '';
  public static translatePolicyMessage ='';
}
export class LangGlobalsEN{
  public static translateSendBtn = 'Send';
  public static translateMsgLabel = 'Message';
  public static translateAdditionalLabel = 'Location*';
  public static translateNameLabel = 'Name*';
  public static translatePolicy = '* Please note our privacy policy';
  public static translateUaqHeadlineLabel = 'Ask your questions';
  public static translateDatapolicy = 'Data policy';
  public static translateImprint = 'Imprint';
  public static translateTechInfo = 'Technical info';
  public static translateSendmail = 'In case of a disturbance, please send a message to';
  public static translateBtnPresentation = 'Presentation';
  public static translateBtnQuestions = 'questions';
  public static translateCurSlide = 'Current slide';
  public static translateSlide = 'Slides';
  public static translateQuestions = 'questions';
  public static translateQuestion = 'question';
  public static translateQuestionsFixed = 'questions';
  public static translateTotal = 'total';
  public static translateTo = 'to';
  public static translateParticipants = 'Participants';
  public static translateSlideHistory = 'Slide history';
  public static translateYour = 'Your';
  public static translateChapter = 'Chapters';
  public static translateqSuccess = 'Your question has been received';
  public static translateqError = 'Please enter a question.';
  public static translateUsername = 'Username';
  public static translatePassword = 'Password';
  public static translatePWRequest = 'Please use your login credentials to access this event';
  public static translatePWSuccess = 'Login successful';
  public static translatePWError = 'Login failed';
  public static translateNoPW = 'Please enter a password';
  public static translatePWtooShort = 'Password must be at least 6 characters';
  public static translateNoUsername = 'Username is required';
  public static translateBtnLogin = 'Login';
  public static translateBtnLogout = 'Logout';
  public static translateLoggedInAs = 'Logged in as';
  public static translateEmailSubject = 'Live-Streaming - Reporting a malfunction';
  public static translateStrawpollTitle = 'Strawpoll';
  public static translateStrawpollResults = 'Strawpolling Result';
  public static translateStrawpollYourVote = 'Thank you for your vote. You voted';
  public static translateStrawpollVoted = 'voted.';
  public static translateStrawpollResultDefer = 'Strawpoll Results will be published soon';
  public static translateStrawpollClick = 'Confirm your choice';
  public static translateStrawpollFailed = 'Voting failed. Please select one of the options above!';
  public static translateChoice = 'Your Choice';
  public static translateResult = 'Result';
  public static translateVoteNow = 'Vote now!';
  public static translateVoteInformation = 'Questions received';
  public static translateVoteCollapse = 'Question poll';
  public static translateMediaLibraryShort = 'Media library';
  public static translateMediaLibraryLong = 'Media library dashboard';
  public static translateSyncDesctiption = 'Click to stop manually navigating through the questions/chapters. These will then automatically follow the playing video again.';
  public static translateFurtherEvents = 'Further events';
  public static translateEventDescription ="Event description";
  public static translatePolicyName = '';
  public static translatePolicyAdditional = '';
  public static translatePolicyMessage ='';
}
export class LangGlobalsNL{
  public static translateSendBtn = 'Zenden';
  public static translateMsgLabel = 'Bericht';
  public static translateAdditionalLabel = 'Firma*';
  public static translateNameLabel = 'Naam*';
  public static translatePolicy = '* Let op ons privacybeleid';
  public static translateUaqHeadlineLabel = 'Stel hier uw vraag';
  public static translateDatapolicy = 'Gegevensbescherming';
  public static translateImprint = 'Imprint';
  public static translateTechInfo = 'Techniek Info';
  public static translateSendmail = 'In geval van een storing kunt u hier een bericht sturen';
  public static translateBtnPresentation = 'Presentatie';
  public static translateBtnQuestions = 'Vragen';
  public static translateCurSlide = 'Actuele presentatie dia';
  public static translateSlide = 'Presentatie dia';
  public static translateQuestions = 'Vragen';
  public static translateQuestion = 'Vraag';
  public static translateQuestionsFixed = 'Vragen';
  public static translateTotal = 'totaal';
  public static translateTo = 'naar';
  public static translateParticipants = 'Deelnemende';
  public static translateSlideHistory = 'Dialoop';
  public static translateYour = 'Uw';
  public static translateChapter = 'Hoofdstuk';
  public static translateqSuccess = 'Uw vraag werd met succes ingediend';
  public static translateqError = 'Vul een vraagtekst in';
  public static translateUsername = 'Gebruikersnaam';
  public static translatePassword = 'Wachtwoord';
  public static translatePWRequest = 'Om deel te nemen aan de livestream, logt u in met uw inloggegevens';
  public static translatePWSuccess = 'Inloggen succesvol';
  public static translatePWError = 'Login fehlgeschlagen';
  public static translateNoPW = 'Kies een wachtwoord';
  public static translatePWtooShort = 'Het ingevoerde wachtwoord moet ten minste zes tekens lang zijn';
  public static translateNoUsername = 'Voer een gebruikersnaam in';
  public static translateBtnLogin = 'Log in';
  public static translateBtnLogout = 'Log uit';
  public static translateLoggedInAs = 'Geregistreerd als';
  public static translateEmailSubject = 'Live streaming - een storing melden';
  public static translateStrawpollTitle = 'Stemming';
  public static translateStrawpollResults = 'Stemmingsresultaat';
  public static translateStrawpollYourVote = 'Dank u voor uw stem. Je hebt gestemd voor';
  public static translateStrawpollVoted = ' gestemd.';
  public static translateStrawpollResultDefer = 'De resultaten zullen binnenkort worden bekendgemaakt';
  public static translateStrawpollClick = 'Bevestig uw keuze';
  public static translateStrawpollFailed = 'De stemming is mislukt. Kies een van de opties!';
  public static translateChoice = 'Uw keuze';
  public static translateResult = 'Resultaten';
  public static translateVoteNow = 'Uw vragen - Stem nu!';
  public static translateVoteInformation = 'Ontvangen vragen';
  public static translateVoteCollapse = 'Stemming over vragen';
  public static translateMediaLibraryShort = 'Mediatheek';
  public static translateMediaLibraryLong = 'Media Bibliotheek dashboard';
  public static translateSyncDesctiption = 'Klik op om de handmatige navigatie door de vragen/hoofdstukken te stoppen. Deze zullen dan automatisch de video volgen die opnieuw wordt afgespeeld.';
  public static translateFurtherEvents = 'verdere gebeurtenissen';
  public static translateEventDescription ="Beschrijving van het evenement"
  public static translatePolicyName = '';
  public static translatePolicyAdditional = '';
  public static translatePolicyMessage ='';
}
export class LangGlobalsPT{
  public static translateSendBtn = 'Enviar';
  public static translateMsgLabel = 'Mensagem';
  public static translateAdditionalLabel = 'Empresa*';
  public static translateNameLabel = 'Nome*';
  public static translatePolicy = '* Por favor note a nossa política de privacidade';
  public static translateUaqHeadlineLabel = 'Faça aqui a sua pergunta';
  public static translateDatapolicy = 'Protecção de dados';
  public static translateImprint = 'Impressão';
  public static translateTechInfo = 'Technik Info';
  public static translateSendmail = 'Informação Técnica';
  public static translateBtnPresentation = 'Apresentação';
  public static translateBtnQuestions = 'Perguntas';
  public static translateCurSlide = 'Diapositivo actual';
  public static translateSlide = 'Slides';
  public static translateQuestions = 'Perguntas';
  public static translateQuestion = 'Pergunta';
  public static translateQuestionsFixed = 'Perguntas';
  public static translateTotal = 'total';
  public static translateTo = 'para';
  public static translateParticipants = 'Participando';
  public static translateSlideHistory = 'Filme realizado';
  public static translateYour = 'O seu';
  public static translateChapter = 'Capítulo';
  public static translateqSuccess = 'A sua pergunta foi submetida com sucesso';
  public static translateqError = 'Por favor introduza um texto de pergunta';
  public static translateUsername = 'Nome de utilizador';
  public static translatePassword = 'Senha';
  public static translatePWRequest = 'Para participar na transmissão ao vivo, por favor inicie sessão com os seus dados de login';
  public static translatePWSuccess = 'Login com sucesso';
  public static translatePWError = 'Login falhou';
  public static translateNoPW = 'Por favor introduza uma palavra-passe';
  public static translatePWtooShort = 'Por favor introduza uma palavra-passe';
  public static translateNoUsername = 'Bitte geben Sie einen Benutzernamen ein';
  public static translateBtnLogin = 'Iniciar Sessão';
  public static translateBtnLogout = 'Terminar sessão';
  public static translateLoggedInAs = 'Registado como';
  public static translateEmailSubject = 'Live streaming - reportar uma avaria';
  public static translateStrawpollTitle = 'Votação';
  public static translateStrawpollResults = 'Resultado da votação';
  public static translateStrawpollYourVote = 'Obrigado pelo vosso voto. Votou em';
  public static translateStrawpollVoted = ' coordenado.';
  public static translateStrawpollResultDefer = 'Os resultados serão publicados em breve';
  public static translateStrawpollClick = 'Confirme a sua escolha';
  public static translateStrawpollFailed = 'A reconciliação falhou. Por favor, seleccione uma das opções!';
  public static translateChoice = 'A sua escolha';
  public static translateResult = 'Resultados';
  public static translateVoteNow = 'Vote!';
  public static translateVoteInformation = 'Eingegangene Fragen';
  public static translateVoteCollapse = 'Perguntas recebidas';
  public static translateMediaLibraryShort = 'Mediateca';
  public static translateMediaLibraryLong = 'Painel da biblioteca multimédia';
  public static translateSyncDesctiption = 'Clique para parar de navegar manualmente através das perguntas/capítulos. Estes seguirão então automaticamente o vídeo a ser reproduzido novamente.';
  public static translateFurtherEvents = 'outros eventos';
  public static translateEventDescription ="Descrição do evento"
  public static translatePolicyName = '';
  public static translatePolicyAdditional = '';
  public static translatePolicyMessage ='';
}
export class LangGlobalsES{
  public static translateSendBtn = 'Enviar';
  public static translateMsgLabel = 'Mensaje';
  public static translateAdditionalLabel = 'Ubicación*';
  public static translateNameLabel = 'Nombre*';
  public static translatePolicy = '* Tenga en cuenta nuestra política de privacidad';
  public static translateUaqHeadlineLabel = 'Haga sus preguntas';
  public static translateDatapolicy = 'Política de datos';
  public static translateImprint = 'Pie de imprenta';
  public static translateTechInfo = 'Información técnica';
  public static translateSendmail = 'En caso de que se produzcan molestias, le rogamos que envíe un mensaje a';
  public static translateBtnPresentation = 'Presentación';
  public static translateBtnQuestions = 'Preguntas';
  public static translateCurSlide = 'Diapositiva actual';
  public static translateSlide = 'Diapositivas';
  public static translateQuestions = 'Preguntas';
  public static translateQuestion = 'Pregunta';
  public static translateQuestionsFixed = 'Preguntas';
  public static translateTotal = 'total';
  public static translateTo = 'a';
  public static translateParticipants = 'Participantes';
  public static translateSlideHistory = 'Historia de las diapositivas';
  public static translateYour = 'Su';
  public static translateChapter = 'Capítulo';
  public static translateqSuccess = 'Su pregunta ha sido recibida';
  public static translateqError = 'Por favor, introduzca una pregunta.';
  public static translateUsername = 'Nombre de usuario';
  public static translatePassword = 'Contraseña';
  public static translatePWRequest = 'Utilice sus credenciales de acceso para acceder a este evento';
  public static translatePWSuccess = 'Inicio de sesión con éxito';
  public static translatePWError = 'Fallo en el inicio de sesión';
  public static translateNoPW = 'Por favor, introduzca una contraseña';
  public static translatePWtooShort = 'La contraseña debe tener al menos 6 caracteres';
  public static translateNoUsername = 'El nombre de usuario es obligatorio';
  public static translateBtnLogin = 'Inicio de sesión';
  public static translateBtnLogout = 'cierre de sesión';
  public static translateLoggedInAs = 'Conectado como';
  public static translateEmailSubject = 'Live-Streaming - Informar de una avería';
  public static translateStrawpollTitle = 'Collar de paja';
  public static translateStrawpollResults = 'Resultado de la votación a pie de urna';
  public static translateStrawpollYourVote = 'Gracias por su voto. Has votado';
  public static translateStrawpollVoted = 'votó.';
  public static translateStrawpollResultDefer = 'Los resultados de la encuesta se publicarán en breve';
  public static translateStrawpollClick = 'Confirme su elección';
  public static translateStrawpollFailed = 'La votación ha fallado. Por favor, seleccione una de las opciones anteriores.';
  public static translateChoice = 'Su elección';
  public static translateResult = 'Resultado';
  public static translateVoteNow = '¡Vota ahora!';
  public static translateVoteInformation = 'Preguntas recibidas';
  public static translateVoteCollapse = 'Pregunta de la encuesta';
  public static translateMediaLibraryShort = 'Mediateca';
  public static translateMediaLibraryLong = 'Tablero de la mediateca';
  public static translateSyncDesctiption = 'Haga clic para dejar de navegar manualmente por las preguntas/capítulos. Éstas volverán a seguir automáticamente el vídeo en reproducción.';
  public static translateFurtherEvents = 'Otros eventos';
  public static translateEventDescription ="Descripción del evento"
  public static translatePolicyName = '';
  public static translatePolicyAdditional = '';
  public static translatePolicyMessage ='';
}
export class LangGlobalsCN{
  public static translateSendBtn = '发送';
  public static translateMsgLabel = '留言';
  public static translateAdditionalLabel = '地点*';
  public static translateNameLabel = '命名*';
  public static translatePolicy = '* 请注意我们的隐私政策';
  public static translateUaqHeadlineLabel = '提出您的问题';
  public static translateDatapolicy = '数据政策';
  public static translateImprint = '版本说明';
  public static translateTechInfo = '技术信息';
  public static translateSendmail = '如遇干扰，请发信息至';
  public static translateBtnPresentation = '演示文稿';
  public static translateBtnQuestions = '问题';
  public static translateCurSlide = '当前幻灯片';
  public static translateSlide = '幻灯片';
  public static translateQuestions = '问题';
  public static translateQuestion = '问题';
  public static translateQuestionsFixed = '问题';
  public static translateTotal = '共计';
  public static translateTo = '至';
  public static translateParticipants = '参与者';
  public static translateSlideHistory = '幻灯片历史';
  public static translateYour = '你的';
  public static translateChapter = '章节';
  public static translateqSuccess = '您的问题已收到';
  public static translateqError = '请输入一个问题。';
  public static translateUsername = '帐号';
  public static translatePassword = '密码';
  public static translatePWRequest = '请使用您的登录凭证来访问此活动';
  public static translatePWSuccess = '登录成功';
  public static translatePWError = '登录失败';
  public static translateNoPW = '请输入密码';
  public static translatePWtooShort = '密码必须至少有6个字符';
  public static translateNoUsername = '用户名是必需的';
  public static translateBtnLogin = '登录';
  public static translateBtnLogout = '登出';
  public static translateLoggedInAs = '登录为';
  public static translateEmailSubject = '直播--报告故障';
  public static translateStrawpollTitle = '稻草人';
  public static translateStrawpollResults = '抽签结果';
  public static translateStrawpollYourVote = '感谢你的投票。您的投票';
  public static translateStrawpollVoted = '经表决通过.';
  public static translateStrawpollResultDefer = '抽样调查的结果将很快公布';
  public static translateStrawpollClick = '确认您的选择';
  public static translateStrawpollFailed = '投票失败。请从上面的选项中选择一个!';
  public static translateChoice = '您的选择';
  public static translateResult = '结果';
  public static translateVoteNow = '现在就投票吧!';
  public static translateVoteInformation = '收到的问题';
  public static translateVoteCollapse = '问题投票';
  public static translateMediaLibraryShort = '媒体库';
  public static translateMediaLibraryLong = '媒体库仪表板';
  public static translateSyncDesctiption = '点击停止手动浏览问题/章节。然后，这些将自动再次跟随播放的视频。';
  public static translateFurtherEvents = '进一步的活动';
  public static translateEventDescription ="事件描述";
  public static translatePolicyName = '';
  public static translatePolicyAdditional = '';
  public static translatePolicyMessage ='';
}
export class LangGlobalsJP{
  public static translateSendBtn = '送信';
  public static translateMsgLabel = 'メッセージ';
  public static translateAdditionalLabel = '会社概要*';
  public static translateNameLabel = '名称*';
  public static translatePolicy = '* 個人情報保護方針にご注意ください';
  public static translateUaqHeadlineLabel = 'ご質問はこちら';
  public static translateDatapolicy = 'データ保護';
  public static translateImprint = 'インプリント';
  public static translateTechInfo = '技術情報';
  public static translateSendmail = '障害が発生した場合、こちらからメッセージを送ることができます。';
  public static translateBtnPresentation = 'プレゼンテーション';
  public static translateBtnQuestions = 'ご質問';
  public static translateCurSlide = '現在のスライド';
  public static translateSlide = 'スライド';
  public static translateQuestions = 'ご質問';
  public static translateQuestion = '質問';
  public static translateQuestionsFixed = 'ご質問';
  public static translateTotal = 'ぜんすう';
  public static translateTo = 'まで';
  public static translateParticipants = '参加中';
  public static translateSlideHistory = '映画上映';
  public static translateYour = 'あなたの';
  public static translateChapter = '章';
  public static translateqSuccess = 'ご質問は正常に送信されました';
  public static translateqError = '質問テキストを入力してください';
  public static translateUsername = 'ユーザー名';
  public static translatePassword = 'パスワード';
  public static translatePWRequest = 'ライブ配信に参加するには、ログインデータでログインしてください';
  public static translatePWSuccess = 'ログイン成功';
  public static translatePWError = 'ログインに失敗しました';
  public static translateNoPW = 'パスワードを入力してください';
  public static translatePWtooShort = '入力するパスワードは6文字以上でなければならない';
  public static translateNoUsername = 'ユーザー名を入力してください';
  public static translateBtnLogin = 'ログイン';
  public static translateBtnLogout = 'ログアウト';
  public static translateLoggedInAs = 'として登録されています';
  public static translateEmailSubject = 'ライブストリーミング - 故障の報告';
  public static translateStrawpollTitle = '投票';
  public static translateStrawpollResults = '投票結果';
  public static translateStrawpollYourVote = '投票ありがとうございました。に投票しました';
  public static translateStrawpollVoted = ' に投票した。';
  public static translateStrawpollResultDefer = '結果は近日中に公開予定です';
  public static translateStrawpollClick = '選択内容を確認する';
  public static translateStrawpollFailed = '和解に失敗。選択してください';
  public static translateChoice = 'あなたの選択';
  public static translateResult = '結果';
  public static translateVoteNow = '投票しよう';
  public static translateVoteInformation = '寄せられた質問';
  public static translateVoteCollapse = '質問事項の投票';
  public static translateMediaLibraryShort = 'メディアライブラリ';
  public static translateMediaLibraryLong = 'メディアライブラリーダッシュボード';
  public static translateSyncDesctiption = 'クリックすると、問題/チャプターの手動によるナビゲーションを停止します。これらは、再び再生されるビデオに自動的に追従します。';
  public static translateFurtherEvents = 'その他のイベント';
  public static translateEventDescription ="イベント内容";
  public static translatePolicyName = '';
  public static translatePolicyAdditional = '';
  public static translatePolicyMessage ='';
}
