import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';

import { catchError, retry } from 'rxjs/operators';

import { LogService } from './log.service';

import { MyStream } from './myStream';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserAssignService {
  uInternal = false;

  // serverList: string[] = [
  //   'stream05-ssl.dev.rwe.com/westenergie',
  //   'stream06-ssl.dev.rwe.com/westenergie',
  //   'westenergie.video-archiv.info/westenergie',
  // ]; // Westenergie
  // serverList: string[] = ['']; // Kein Interner Server
  // serverList: string[] = [
  //   'wow-103-playout.rwe.com/rwe',
  //   'wow-107-playout.rwe.com/rwe',
  //   'rwe.video-archiv.info/rwe',
  // ]; // RWE
  // serverList: string[] = ['lhd.video-archiv.info/lhd/api']; // LHD

  serverList: string [] = environment.serverList;

  uFail: number;

  uCount = 0;

  uComplete = false;

  uServer: string;

  private api = environment.apiUrl + 'api';

  constructor(private http: HttpClient, private logger: LogService) {}

  isInternalUser(): boolean {
    this.serverList.sort(() => Math.random() - 0.5);

    for (let value of this.serverList) {
      // this.logger.log(this.testServer(value));
      const t = this.http
        .get(`https://${value}/api/pixel.json`, {
          observe: 'response',
          responseType: 'json',
        })
        .subscribe(
          (data) => {
            // Initiate IPCheck and Loop Break
            this.uFail = data.status;
            this.setuCount();
            this.setInternal(true, value);
          },
          (error) => {
            this.setuCount();
          }
        );
      if (this.uFail === 200) {
        this.logger.log('Successfull Check at: ' + value);

        break;
      }
    }

    return false;
  }
  getInternalStream() {
    // get Address from Internal Server.

    let y = this.getSuccessServer();
    return this.http
      .get<MyStream>(`https://${y}/api/api.php?x=getStream`)
      .pipe(retry(3), catchError(this.errorHandler));
  }
  logFailedInternal() {
    return this.http
      .get<any>(`${this.api}/api.php?x=logFail`)
      .pipe(retry(3), catchError(this.errorHandler));
  }
  setInternal(isInternal: boolean, value: string) {
    this.uInternal = isInternal;
    this.uServer = value;
  }
  getInternal(): boolean {
    return this.uInternal;
  }
  getSuccessServer() {
    return this.uServer;
  }
  setuCount() {
    this.uCount += 1;
  }
  getComplete() {
    if (this.uCount === this.serverList.length) {
      return true;
    } else {
      return false;
    }
  }

  private errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error('Fehler aufgetreten!');

    return throwError(error);
  }
}
