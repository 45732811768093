<div
  class='hr_slide_history'
  [ngClass]="{
    negMarginTop: this.eStatus.phase !== 'post-live' && screenwidth > 1023
  }"
>
  <div class='hr_dropdown_slides'>
    <div class='hr_collapsible_slides' *ngIf='screenwidth > 1023'>
      <button
        type='button'
        class='hr_btn_collapsible'
        (click)='toggleSlideflow()'
      >
        <span
          [ngClass]="{
            active: this.showSlideFlow == 'in'
          }"
          class='arrow'
        >
          <span></span>
          <span></span>
        </span>
        <span>{{ eLangGlob.translateSlideHistory }}</span>
      </button>
    </div>
    <div class='hr_current_slide' *ngIf="eStatus.phase != 'post-live' && screenwidth > 1023">
      <span
        class='cur_slide_left'
        [ngClass]='{
          cur_slide_left_disabled: !this.slideIsCustom
        }'
      ></span>
      <button
        class='btn_current_slide'
        [ngClass]='{
          btn_current_slide_disabled: !this.slideIsCustom
        }'
        (click)='returnToLiveSlide()'
      >
        <span>{{ eLangGlob.translateCurSlide }}</span>
      </button>
      <span
        class='cur_slide_right'
        [ngClass]='{
          cur_slide_right_disabled: !this.slideIsCustom
        }'
      ></span>
    </div>
  </div>
  <div class='hr_collapsed_slides' [@slideInOut]='showSlideFlow'>
    <div class='historyBox'>
      <button (click)="scroll('left')" class='ff arrow-left'></button>
      <div class='coverflow_style' dir='slideDirection' #slideHistoryContent>
        <lse-slideflow-list-item
          (centerSlide)='scrollToCenter($event)'
          (vodEvent)='handleEvent($event)'
          (showElement)="onshowElement('slides')"
          (playAtTimestamp)='playAtFunction($event)'
          *ngFor='let p of playlist; trackBy: trackSlideflow'
          [slide]='p'
          [path]='path'
          #slideDiv
        ></lse-slideflow-list-item>
      </div>
      <button (click)="scroll('right')" class='ff arrow-right'></button>
    </div>
  </div>
  <hr *ngIf='screenwidth > 1023' />
</div>
