import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { Slide } from '../shared/slide';
import { SlideStoreService } from '../shared/slide-store.service';

@Component({
  selector: 'lse-slideflow-list-item',
  templateUrl: './slideflow-list-item.component.html',
  styleUrls: ['./slideflow-list-item.component.css']
})
export class SlideflowListItemComponent implements OnInit {
  @Input() slide: Slide;
  @Input() path: string;
  @Output() vodEvent = new EventEmitter();
  @Output() centerSlide = new EventEmitter();
  @Output() showElement = new EventEmitter();
  @Output() playAtTimestamp = new EventEmitter();

  stateInterval: any;
  state: string;
  highlightInterval: any;
  highlight = false;
  noCacheNumber: number;
  slideDirection: string;

  constructor(private es: EventconfigStoreService, private slideStoreService: SlideStoreService) {
  }

  ngOnInit(): void {
    this.noCacheNumber = new Date().getTime();
    this.setHighlight();
    this.stateInterval = setInterval(() => { // Initialisierung Interval zum Abruf des Status prelive, live, postlive.
      this.getEventState();
    }, 2000);
    this.slideDirection = this.es.eState === 'live' ? 'rtl' : 'ltr';
  }

  setActiveSlide() {
    console.log('setActiveSlide not event-store');
    this.es.setActiveSlide(this.slide);
    this.centerSlide.emit(this.slide.uid);
    this.showElement.emit('slides');
  }

  getEventState() {
    this.state = this.es.getEventState();
  }

  clickVodSlide() {
    console.log('clickVodSlide');
    this.es.setActiveSlide(this.slide);
    let seconds = this.slideTCtoSeconds(this.slide.tc);
    this.vodEvent.emit(seconds);
    this.showElement.emit('slides');
  }

  playbackAtTimestamp(tc: string) {
    console.log('playbackAtTimestamp');
    this.playAtTimestamp.emit(this.slideTCtoSeconds(tc));
    this.es.setActiveSlide(this.slide);

  }

  slideTCtoSeconds(tc: string): number {
    let slideTimeCodeArray = tc.split(':');
    return (Number.parseInt(slideTimeCodeArray[0]) * 60 +
        Number.parseInt(slideTimeCodeArray[1])) * 60 +
      Number.parseInt(slideTimeCodeArray[2]);
  }

  handleMissingSlide(event: Event) {
    let target = event.target as HTMLImageElement;
    const imgTypes = ['.jpg?'];//, '.png?', '.webp?', '.jpeg?', '.gif?'];


    imgTypes.forEach((imgType) => {
      let newSrc = target.src.replace('.webp?', imgType).replace('/slide-previews/', '/');
      if (this.imageExists(newSrc)) {
        target.src = newSrc;
        return;
      }else{
        target.src = 'assets/images/placeholder.jpg';
      }
    });
  }

  imageExists(image_url: string) {

    let http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status != 404;

  }

  //zum wiederfinden = SLIDE mySyncSlide setActive
  //Diese methode bestimmt die geaschwindigkeit des schaltens der slides
  setHighlight() {
    setInterval(() => {
      this.highlight = this.es.getActiveSlide().uid === this.slide.uid;
    }, 100);
  }
}
