import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'lse-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  email: string;
  user: any;
  eLangGlob: any;

  constructor(private authService: AuthService,
              private tokenStorage: TokenStorageService,
              private router: Router, private es: EventconfigStoreService) {
  }

  ngOnInit() {
    this.eLangGlob = this.es.getLangGlobals();
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.user = this.tokenStorage.getUser();
      this.email = this.user.email;
    }
  }

  onSubmit() {
    this.authService.login(this.form).subscribe(
      data => {
        this.tokenStorage.saveToken(data.jwt);
        this.tokenStorage.saveUser(data);
        this.email = data.email;
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.reloadPage();
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
        setTimeout(() => {
          this.removeHint();
        }, 3000);
      }
    );
  }

  removeHint() {
    this.isLoginFailed = false;
  }

  reloadPage() {
    window.location.reload();
  }

}
