import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';

import { TokenStorageService } from '../_services/token-storage.service';


@Injectable()

export class TokenInterceptor implements HttpInterceptor {  constructor(private es: EventconfigStoreService, private token: TokenStorageService) {}

intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  const token = this.token.getToken();
  request = request.clone({
      setHeaders: {
        'X-Eventtoken' : `${this.es.getInitCipher()}`,
        Authorization: 'Bearer ' + token
      }
    });
   return next.handle(request);
  }
}
