<div class='slide_container'>
  <img #slideId class='ui image'
       src='{{ path }}{{ slide.slide }}?{{noCacheNumber}}'
  />
  <div class='slide_bar'>
    <div class='fullscreen_button'>
      <i (click)='openFullscreen()' class='fullscreenicon'></i>
    </div>
  </div>
</div>
