import { Component, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { PlayerStoreService } from '../shared/player-store.service';
import { Question } from '../shared/question';
import { QuestionStoreService } from '../shared/question-store.service';

@Component({
  selector: 'lse-user-vote-question-list-item',
  templateUrl: './user-vote-question-list-item.component.html',
  styleUrls: ['./user-vote-question-list-item.component.css'],
})
export class UserVoteQuestionListItemComponent implements OnInit {
  @Input() question: Question;
  @Output() myEvent = new EventEmitter();
  @Output() tap = new EventEmitter();

  questionTxt: string;
  screenwidth = document.body.offsetWidth;
  highlight = false;
  votes = [];
  votedFor = false;
  authorLocation = '';

  constructor(private es: EventconfigStoreService, private vs: PlayerStoreService, private qs: QuestionStoreService) {}

  ngOnInit(): void {
    this.questionTxt = this.question.txt_raw;
    if (this.es.getActiveLang() === 'de') {
      this.questionTxt = this.question.txt_de;
    } else if (this.es.getActiveLang() === 'en') {
      this.questionTxt = this.question.txt_en;
    } else {
      this.questionTxt = this.question.txt_raw;
    }
    this.questionTxt = this.question.txt_raw;
    this.votes = this.qs.getVotes();
    if (this.votes !== null) {
      if (this.votes.includes(this.question.id)) {
        this.votedFor = true;
      }
    }
    this.authorLocation = this.question.author;
    if (this.question.author === '' || this.question.author === undefined) {
      this.authorLocation = 'Anonymous';
    }
    if (this.question.location && this.question.location !== '' && this.question.location !== 'XX') {
      this.authorLocation += ', ' + this.question.location;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
  }

  voteUAQ() {
    this.qs.voteUAQ(this.question.id).subscribe(
      (data) => {},
      (err) => {}
    );
/*    console.log(this.qs.saveVotes(this.question.id));*/
    this.votedFor = true;
  }
}
