import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { LogService } from '../shared/log.service';
import { Slide } from '../shared/slide';
import { SlideStoreService } from '../shared/slide-store.service';

@Component({
  selector: 'lse-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.css']
})
export class SlideComponent implements OnInit, AfterViewInit {
  @Input() slide: Slide;
  @Input() path: string;

  slidePath: string;
  noCacheNumber: number;
  @ViewChild('slideId') slidefs;


  constructor(private ss: SlideStoreService, private logger: LogService) {
  }

  ngOnInit(): void {
    this.noCacheNumber = new Date().getTime();
    this.logger.log('Init Slide: ' + this.path);

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.slidePath = this.ss.getSlidePath();
    }, 1000);
  }

  openFullscreen() {
    const elem = this.slidefs.nativeElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }
}
