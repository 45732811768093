<div class="voting_item">
  <div class="content">
    <div class="small author">{{ authorLocation }}</div>
    <div>
      {{ questionTxt }}
    </div>
  </div>
  <div class="side">
    <div class="small votes">Votes: {{ question.votes }}</div>
    <div
      class=""
      *ngIf="votedFor"
    >
      <i class="ff thumbsup_voted"></i>
    </div>
    <div
      class=""
      *ngIf="!votedFor"
    >
      <i
        (click)="voteUAQ()"
        class="ff thumbsup"
      ></i>
    </div>
  </div>
</div>
<hr />
