<div class="hr_collapsible_voting" *ngIf="screenwidth <= 1023">
  <button type="button" class="hr_btn_collapsible" (click)="toggleUVQ()">
    <span
      [ngClass]="{
        active: this.showUVQ == 'in'
      }"
      class="arrow"
    >
      <span></span>
      <span></span>
    </span>
    <span>{{ eLangGlob.translateVoteCollapse }}</span>
  </button>
</div>
<div class="hr_collapsed_voting" [@slideInOut]="showUVQ">
  <div
    class="hr_voting_box"
    [ngClass]="{
      margintop: screenwidth <= 1023
    }"
  >
    <div class="votingHeadline">
      <div style="font-weight: bold">
        {{ eLangGlob.translateVoteInformation }}
      </div>
      <div>{{ eLangGlob.translateVoteNow }}</div>
    </div>
    <hr>
    <div id="votingData">
      <lse-user-vote-question-list-item
        (myEvent)="handleEvent($event)"
        class="item"
        *ngFor="let q of questions"
        [question]="q"
      ></lse-user-vote-question-list-item>

      <!-- <div *ngIf="!questions" class="ui active dimmer">
        <div class="ui large text loader">Loading...</div>
      </div> -->
    </div>
  </div>
</div>
<hr *ngIf="screenwidth <= 1023" />
