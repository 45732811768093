<div class="hr_strawpoll" *ngIf="eStatus.user_strawpoll">
  <div class="hr_collapsible_strawpoll" (click)="toggleStrawpoll()">
    <button type="button" class="hr_btn_collapsible">
      <span
        [ngClass]="{
          active: this.showStrawpoll == 'in'
        }"
        class="arrow"
      >
        <span></span>
        <span></span>
      </span>
      <span>{{ eLangGlob.translateStrawpollTitle }}</span>
    </button>
  </div>
  <div class="hr_collapsed_strawpoll" [@slideInOut]="showStrawpoll">
    <div style="margin-top: 20px">
      <form
        *ngIf="!isSuccessful && vConfig && !hasStrawpollVoted; else results"
        class="ui form"
        name="form"
        (ngSubmit)="f.form.valid && onSubmit()"
        #f="ngForm"
        novalidate
      >
        <h3>{{ vConfig.title }}</h3>
        <div class="field">
          <div
            *ngFor="
              let item of createRange(vConfig.number_options);
              let index = index
            "
            class="radioVote"
          >
            <label>
              <span class="hr_radioButtonText">{{
                vConfig.options[index]
              }}</span>
              <input
                style="font-size: 16px"
                type="radio"
                [value]="index + 1"
                name="option_{{ index + 1 }}"
                [(ngModel)]="StrawpollVote"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="field" class="strawpoll_submit">
          <span class="strawpoll_button_left"></span>
          <button class="hr_strawpoll_submit">
            {{ eLangGlob.translateStrawpollClick }}
          </button>
          <span class="strawpoll_button_right"></span>
        </div>

        <div
          class="ui visible warning message"
          *ngIf="f.submitted && voteFailed"
        >
          {{ eLangGlob.translateStrawpollFailed }}<br />{{ errorMessage }}
        </div>
      </form>
    </div>
  </div>
  <hr />
</div>
<ng-template #results>
  <div
    *ngIf="eStatus.show_strawpoll_results && StrawpollVotingData"
    style="display: block"
  >
    <span>{{ eLangGlob.translateResult }}</span>
    <canvas
      id="chart"
      baseChart
      [data]="pieChartData"
      [labels]="pieChartLabels"
      [type]="pieChartType"
      [options]="pieChartOptions"
      [plugins]="pieChartPlugins"
      [legend]="pieChartLegend"
    >
    </canvas>
    <div>
      <div>
        {{ vConfig.title }}
      </div>
      <div>
        {{ eLangGlob.translateChoice }}:
        {{ vConfig.options[hasStrawpollVotedFor - 1] }}
      </div>
    </div>
  </div>
  <div *ngIf="!eStatus.show_strawpoll_results && !wait">
    <div>
      {{ eLangGlob.translateStrawpollYourVote }}
      "{{ vConfig.options[hasStrawpollVotedFor - 1] }}"
      {{ eLangGlob.translateStrawpollVoted }}
      {{ eLangGlob.translateStrawpollResultDefer }}
    </div>
  </div>
</ng-template>
