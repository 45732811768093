import { Eventstatus } from './../shared/eventstatus';
import { EventconfigStoreService } from './../shared/eventconfig-store.service';
import { EventsetupStoreService } from './../shared/eventsetup-store.service';
import { Eventsetup } from './../shared/eventsetup';
import { Component, Input, HostListener, OnInit, Output } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { EventEmitter } from '@angular/core';
import { LogService } from '../shared/log.service';
import { Question } from '../shared/question';
import { QuestionStoreService } from '../shared/question-store.service';

@Component({
  selector: 'lse-user-vote-question-list',
  templateUrl: './user-vote-question-list.component.html',
  styleUrls: ['./user-vote-question-list.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1.5s ease-in', style({ height: 0, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 0, opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          overflow: 'hidden',
          height: '*',
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out')),
    ]),
  ],
})
export class UserVoteQuestionListComponent implements OnInit {
  @Input() pageSize: number;
  @Input() eStatus: Eventstatus;
  @Output() playAtEvent = new EventEmitter();

  questions: Question[];

  page = 1;
  count = 0;

  holdMyTime: any;
  screenwidth = document.body.offsetWidth;
  eSetup: Eventsetup;
  eLangGlob: any;

  showUVQ = 'in';
  vodHeight: any;

  constructor(
    private qs: QuestionStoreService,
    private logger: LogService,
    private ep: EventsetupStoreService,
    private es: EventconfigStoreService
  ) {}

  ngOnInit(): void {
    this.retrieveCount();
    this.eLangGlob = this.es.getLangGlobals();
    this.eSetup = this.ep.getStoredSetup();
    setInterval(() => {
      this.retrieveCount();
    }, 3000);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
  }

  retrieveCount(): void {
    this.qs.getUAQCount().subscribe(
      (response) => {
        this.count = response;
        this.retrieveUAQMsg();
      },
      (error) => {
        this.logger.log(error);
      }
    );
  }
  retrieveUAQMsg() {
    this.qs.getAllUAQ(0, this.page).subscribe(
      (response) => {
        this.questions = response;
      },
      (error) => {
        this.logger.log(error);
      }
    );
  }

  handleEvent(value) {
    this.holdMyTime = value;
    this.playAtEvent.emit(value);
    // this.logger.log(value);
  }

  toggleUVQ() {
    this.showUVQ = this.showUVQ === 'out' ? 'in' : 'out';
  }
}
