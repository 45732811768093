import videojs from 'video.js/dist/video.min.js';
const MenuItem = videojs.getComponent('MenuItem');
const Component = videojs.getComponent('Component');
class SourceMenuItem extends MenuItem {
  /**
   * Create SourceMenuItems and sort them
   *
   * @param {videojs.Player} player
   * A videojs player
   * @param {{label, index, selected, sortValue, selectable: true, multiSelectable: false}} options
   * Multiselectable
   */
  constructor(player, options) {
    options.selectable = true;
    options.multiSelectable = false;

    super(player, options);
  }

  /**
   * Function called whenever a SourceMenuItem is clicked
   */
  handleClick($event) {
    const selected = this.options_;
    const maxQuality = this.player_.options_["maxQuality"]
    this.player_.options_["userSelectedQuality"] = selected["index"];
    super.handleClick($event);

    // @ts-ignore
    const levels = [...this.player_.qualityLevels().levels_];

    for (const [index, level] of levels.entries()) {
      // @ts-ignore
      level.enabled = (selected.index === levels.length || selected.index === index) && level.height<=maxQuality
    }
  }

  /**
   * Create SourceMenuItems and sort them
   */
}
Component.registerComponent('SourceMenuItem', SourceMenuItem);

export default SourceMenuItem;
