import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Eventsetup } from './eventsetup';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventsetupStoreService {

  private api = environment.apiUrl + 'api';

  storedSetup: Eventsetup;

  constructor(private http: HttpClient) { }

  getSetup(){
    return this.http.get<Eventsetup>(`${this.api}/api.php?x=get_setup`)
    .pipe(
      retry(1),
      catchError(this.errorHandler)
      );
  }

  setStoredSetup(setup: Eventsetup){
    this.storedSetup = setup;
  }

  getStoredSetup(){
    return this.storedSetup;
  }

  private errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error('Fehler aufgetreten!');
    return throwError(error);
  }
}
