import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const VOTE_KEY = 'strawpoll-has-voted';
const VOTE_RESULT_KEY = 'strawpoll-voted-for';
const SCROLLED = 'Scrolled';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor(private router: Router) { }

  signOut() {
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY +'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0]);
    window.sessionStorage.setItem(TOKEN_KEY +'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0], token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY+'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0]);
  }

  public saveUser(user) {
    window.sessionStorage.removeItem(USER_KEY +'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0]);
    window.sessionStorage.setItem(USER_KEY +'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0], JSON.stringify(user));
  }
  public saveStrawpollVote(vote){
    window.sessionStorage.setItem(VOTE_KEY +'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0], 'true');
    window.sessionStorage.setItem(VOTE_RESULT_KEY +'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0], vote);
  }
  public getStrawpollVoteResult(){
    return sessionStorage.getItem(VOTE_RESULT_KEY +'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0]);
  }
  public getHasStrawpollVoted(){
    if (sessionStorage.getItem(VOTE_KEY +'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0]) === 'true'){
      return true;
    }
    else{
      return false;
    }
  }

  public saveScrolled(){
    window.sessionStorage.setItem(SCROLLED +'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0], 'true');
  }

  public getScrolled(){
    return sessionStorage.getItem(SCROLLED +'-'+ this.router.url.substring(this.router.url.lastIndexOf('/')+1).split('?')[0]);
  }

  public getUser() {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }
}
