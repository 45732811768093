import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EventconfigStoreService } from './../shared/eventconfig-store.service';
import { Eventsetup } from './../shared/eventsetup';
import { EventsetupStoreService } from './../shared/eventsetup-store.service';
import { LogService } from '../shared/log.service';
import { PlayerStoreService } from '../shared/player-store.service';
import { Question } from '../shared/question';
import { QuestionStoreService } from '../shared/question-store.service';

@Component({
  selector: 'lse-vod-question-list',
  templateUrl: './vod-question-list.component.html',
  styleUrls: ['./vod-question-list.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1.5s ease-in', style({ height: 0, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: 0, opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          overflow: 'hidden',
          height: '*'
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '0px'
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ])
  ]
})
export class VodQuestionListComponent implements OnInit {
  @Input() pageSize: number;
  @Output() playAtEvent = new EventEmitter();
  @Output() questionCount = new EventEmitter<number>();

  questions: Question[];
  page = 1;
  vodCount: number;
  vodQDisplay: number[];
  vodFrom: number;
  vodTo: number;
  count = 0;
  pageSizes = [5, 10, 15];
  holdMyTime: any;
  screenwidth = document.body.offsetWidth;
  eSetup: Eventsetup;
  eLangGlob: any;
  showQuestionVod = 'in';
  lastPageEnd;
  manualPageChange: boolean;
  //Question(true) Chapter(false)
  questionOrChapter: Boolean = true;

  constructor(
    private qs: QuestionStoreService,
    private logger: LogService,
    private ep: EventsetupStoreService,
    private es: EventconfigStoreService,
    private vs: PlayerStoreService
  ) {
  }

  ngOnInit(): void {
    this.retrieveCount();
    this.eLangGlob = this.es.getLangGlobals();
    this.eSetup = this.ep.getStoredSetup();
    this.vodQDisplay = this.getFromTo();
    this.vodFrom = this.vodQDisplay[0];
    this.vodTo = this.vodQDisplay[1];
    setInterval(() => {
      this.pageCheck();
    }, 500);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
  }

  retrieveCount(): void {
    this.qs.getVodCount().subscribe(
      (response) => {
        this.count = response;
        this.retrieveVodMsg();
      },
      (error) => {
        this.logger.log(error);
      }
    );
  }

  retrieveVodMsg() {
    if (this.eSetup.questionSwitch) {
      this.qs.getAllPaginationNew().subscribe((data) => {
        /*console.log(data);*/
        this.questions = data['data'];
        this.count = data['data'].length;
        this.questionCount.emit(data['data'].length);
      });
    } else {
      this.qs.getAllPagination().subscribe(
        (response) => {
          /*console.log(response);*/
          this.questions = response;
          this.count = response.length;
          this.questionCount.emit(response.length);
        },
        (error) => {
          this.logger.log(error);
        }
      );
    }
  }

  handlePageChange(page): void {
    const tempNumEnd =
      this.questions[this.questions.length - 1].tc_end.split(':');
    this.lastPageEnd =
      +tempNumEnd[0] * 60 * 60 + +tempNumEnd[1] * 60 + +tempNumEnd[2];
    this.logger.log(
      'Page: ' + this.count + ' Calc: ' + (this.page + 1) * this.pageSize
    );
    if (page === 'plus' && this.count > this.page * this.pageSize) {
      this.page++;
      this.manualPageChange = true;
    }
    if (page === 'minus' && this.page > 1) {
      this.page--;
      this.manualPageChange = true;
    }
    if (page === 'plusAuto' && this.count > this.page * this.pageSize) {
      this.page++;
    }
    if (page === 'minusAuto' && this.page > 1) {
      this.page--;
    }

    this.vodQDisplay = this.getFromTo();
    this.vodFrom = this.vodQDisplay[0];
    this.vodTo = this.vodQDisplay[1];
  }

  handlePageSizeChange(event): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  handleEvent(value) {
    this.holdMyTime = value;
    this.playAtEvent.emit(value);
    this.logger.log(value);
  }

  getFromTo() {
    // Sprachabhängig!
    // return ('Fragen ' + (((this.page - 1) * this.pageSize) + 1) + ' bis ' + ((this.page) * this.pageSize));
    return [(this.page - 1) * this.pageSize + 1, this.page * this.pageSize];
  }

  toggleQuestionsVod() {
    this.showQuestionVod = this.showQuestionVod === 'out' ? 'in' : 'out';
  }

  pageCheck() {
    if (!this.manualPageChange) {
      let numStart: number;
      let numEnd: number;
      let tempNumStart =
        this.questions[(this.page - 1) * this.pageSize].tc_start.split(':');
      numStart =
        +tempNumStart[0] * 60 * 60 + +tempNumStart[1] * 60 + +tempNumStart[2];

      // ungrade anzahl an Fragen müssen abgefanegen werden.
      let tempNumEnd;
      if (this.questions[this.page * this.pageSize - 1] === undefined) {
        tempNumEnd =
          this.questions[this.questions.length - 1].tc_end.split(':');
      } else {
        tempNumEnd =
          this.questions[this.page * this.pageSize - 1].tc_end.split(':');
      }
      //
      numEnd = +tempNumEnd[0] * 60 * 60 + +tempNumEnd[1] * 60 + +tempNumEnd[2];
      if (numEnd < Math.round(this.vs.getCurrentPlayTime())) {
        this.sycQuestions();
      }
      if (numStart > Math.round(this.vs.getCurrentPlayTime())) {
        this.sycQuestions();
      }
    }
  }

  changeManualPageChangeFalse() {
    this.manualPageChange = false;
  }

  sycQuestions() {
    for (let i = 0; i < this.count; i++) {
      let numStart: number;
      let numEnd: number;
      let tempNumStart = this.questions[i].tc_start.split(':');
      numStart =
        +tempNumStart[0] * 60 * 60 + +tempNumStart[1] * 60 + +tempNumStart[2];
      let tempNumEnd = this.questions[i].tc_end.split(':');
      numEnd = +tempNumEnd[0] * 60 * 60 + +tempNumEnd[1] * 60 + +tempNumEnd[2];
      if (
        numStart <= Math.round(this.vs.currentPlayTime) &&
        numEnd >= Math.round(this.vs.currentPlayTime)
      ) {
        this.page = Math.ceil((i + 1) / this.pageSize);
        this.vodQDisplay = this.getFromTo();
        this.vodFrom = this.vodQDisplay[0];
        this.vodTo = this.vodQDisplay[1];
      }
    }
  }
}
