<div class="ui centered grid">
  <div class="login">
    <div class="">
      <div class = "row">
        <div class="loginHeadline">
          <h2>{{eLangGlob.translatePWRequest}}</h2>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <div class="loginForm">
            <form
              *ngIf="!isLoggedIn"
              class="ui form"
              name="form"
              (ngSubmit)="f.form.valid && onSubmit()"
              #f="ngForm"
              novalidate
            >
              <div class="field">
                <label for="email">{{eLangGlob.translateUsername}}</label>
                <input
                  type="text"
                  name="email"
                  [(ngModel)]="form.email"
                  required
                  #email="ngModel"
                />
              </div>
              <div
                class="visible warning message"
                role="alert"
                *ngIf="f.submitted && email.invalid"
              >
              {{eLangGlob.translateNoUsername}}
              </div>
              <div class="field">
                <label for="password">{{eLangGlob.translatePassword}}</label>
                <input
                  type="password"
                  name="password"
                  [(ngModel)]="form.password"
                  required
                  minlength="6"
                  #password="ngModel"
                />
              </div>
              <div
                class="ui visible warning message"
                role="alert"
                *ngIf="f.submitted && password.invalid"
              >
                <div *ngIf="password.errors.required">{{eLangGlob.translateNoPW}}</div>
                <div *ngIf="password.errors.minlength">
                  {{eLangGlob.translatePWtooShort}}
                </div>
              </div>
              <div class="field btnLogin">
                <span class="btn_login_left"></span>
                <button>{{eLangGlob.translateBtnLogin}}</button>
                <span class="btn_login_right"></span>
              </div>
              <div class="field">
                <div
                  class="ui visible warning message"
                  style="width: 100%"
                  role="alert"
                  *ngIf="f.submitted && isLoginFailed"
                >
                {{eLangGlob.translatePWError}}
                </div>
              </div>
            </form>
            <div *ngIf="isLoggedIn" class="ui two column centered grid">
              <div class="row">
                <div class="two columns">
                  <div class="ui visible success message">
                    {{eLangGlob.translateLoggedInAs}}:<strong> {{ email }}</strong
                    ><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
