<div class="hr_collapsible_questions_vod" *ngIf="screenwidth <= 1023">
  <button
    type="button"
    class="hr_btn_collapsible"
    (click)="toggleQuestionsVod()"
  >
    <span
      [ngClass]="{
        active: this.showQuestionVod == 'in'
      }"
      class="arrow"
    >
      <span></span>
      <span></span>
    </span>
    <span>
      <span
        *ngIf="
          !eSetup.questionOrChapter || eSetup.questionOrChapter === undefined
        "
        >{{ eLangGlob.translateQuestions }}
      </span>
      <span *ngIf="eSetup.questionOrChapter"
        >{{ eLangGlob.translateChapter }}
      </span></span
    >
  </button>
</div>
<div
  class="hr_collapsed_question_vod"
  *ngIf="screenwidth <= 1023"
  [@slideInOut]="showQuestionVod"
>
  <div
    class="vod_nav_questions"
    *ngIf="
      screenwidth > 512 && screenwidth <= 1023 && count > eSetup.vod_pageSize
    "
    style="margin-top: 20px"
  >
    <div>
      <div class="vod_nav_control">
        <div
          class="vod_nav_buttons vod_nav_buttons_padding_left"
          (click)="handlePageChange('minus')"
        >
          <span class="arrowLeft"></span>
          {{ eSetup.vod_pageSize }}
        </div>
        <span class="fragen1bis10">
          <span
            *ngIf="
              !eSetup.questionOrChapter ||
              eSetup.questionOrChapter === undefined
            "
            >{{ eLangGlob.translateQuestions }}</span
          >
          <span *ngIf="eSetup.questionOrChapter">{{
            eLangGlob.translateChapter
          }}</span>
          {{ vodFrom }}
          {{ eLangGlob.translateTo }}
          <span *ngIf="vodTo < count">{{ vodTo }}</span>
          <span *ngIf="vodTo >= count">{{ count }}</span>
        </span>
        <div class="vod_nav_buttons" (click)="handlePageChange('plus')">
          {{ eSetup.vod_pageSize }}
          <span class="arrowRight"></span>
        </div>
        <i
          title="{{ eLangGlob.translateSyncDesctiption }}"
          *ngIf="manualPageChange"
          (click)="changeManualPageChangeFalse()"
          class="sync alternate icon"
        ></i>
      </div>
    </div>
    <div>
      <span class="hr_questions_total">
        <span
          *ngIf="
            !eSetup.questionOrChapter || eSetup.questionOrChapter === undefined
          "
          >{{ eLangGlob.translateQuestions }}
        </span>
        <span *ngIf="eSetup.questionOrChapter"
          >{{ eLangGlob.translateChapter }}
        </span>
        &nbsp;{{ eLangGlob.translateTotal }}: {{ count }}
      </span>
    </div>
  </div>
  <div
    class="vod_nav_questions_placeholder"
    *ngIf="
      screenwidth > 512 && screenwidth <= 1023 && !(count > eSetup.vod_pageSize)
    "
  ></div>
  <div
    class="ui middle aligned selection list hr_vod_box"
    *ngIf="screenwidth > 512; else vodBoxMobile"
  >
    <lse-vod-question-list-item
      (myEvent)="handleEvent($event)"
      (changeManualPageChangeFalse)="changeManualPageChangeFalse()"
      class="item"
      *ngFor="
        let q of questions
          | paginate
            : {
                itemsPerPage: pageSize,
                currentPage: page,
                totalItems: count
              }
      "
      [question]="q"
    ></lse-vod-question-list-item>

    <div *ngIf="!questions" class="ui active dimmer">
      <div class="ui large text loader">Loading...</div>
    </div>

    <p *ngIf="questions && !questions.length">
      There aren't any Questions yet :-(
    </p>
  </div>
  <ng-template #vodBoxMobile>
    <div
      class="question_wrapper"
      (swipeleft)="handlePageChange('plus')"
      (swiperight)="handlePageChange('minus')"
    >
      <div class="vod_nav_questions_mobile">
        <div (click)="handlePageChange('minus')">
          <i class="ff arrow-left"></i>
        </div>
        <div class="center">
          <span>
            <span
              *ngIf="
                !eSetup.questionOrChapter ||
                eSetup.questionOrChapter === undefined
              "
              >{{ eLangGlob.translateQuestions }}
            </span>
            <span *ngIf="eSetup.questionOrChapter"
              >{{ eLangGlob.translateChapter }}
            </span>
            {{ page }}/{{ count }}</span
          >
          <i
            *ngIf="this.manualPageChange"
            class="sync alternate icon"
            (click)="changeManualPageChangeFalse()"
          ></i>
        </div>

        <div>
          <div (click)="handlePageChange('plus')">
            <i class="ff arrow-right"></i>
          </div>
        </div>
      </div>
      <lse-vod-question-list-item
        (myEvent)="handleEvent($event)"
        (changeManualPageChangeFalse)="changeManualPageChangeFalse()"
        class="item"
        *ngFor="
          let q of questions
            | paginate
              : {
                  itemsPerPage: pageSize,
                  currentPage: page,
                  totalItems: count
                }
        "
        [question]="q"
      ></lse-vod-question-list-item>

      <div *ngIf="!questions" class="ui active dimmer">
        <div class="ui large text loader">Loading...</div>
      </div>

      <p *ngIf="questions && !questions.length">
        There aren't any Questions yet :-(
      </p>
    </div>
  </ng-template>
</div>
<div class="hr_collapsed_question_vod" *ngIf="screenwidth > 1023">
  <div
    class="vod_nav_questions"
    *ngIf="screenwidth > 512 && screenwidth <= 1023"
    style="margin-top: 20px"
  >
    <div>
      <input
        (click)="handlePageChange('minus')"
        type="button"
        class="vod_nav_buttons"
        value="-{{ eSetup.vod_pageSize }}"
      />
      <span class="fragen1bis10">
        <span
          *ngIf="
            !eSetup.questionOrChapter || eSetup.questionOrChapter === undefined
          "
          >{{ eLangGlob.translateQuestions }}
        </span>
        <span *ngIf="eSetup.questionOrChapter"
          >{{ eLangGlob.translateChapter }}
        </span>
        {{ vodFrom }} {{ eLangGlob.translateTo }} {{ vodTo }}
      </span>
      <input
        type="button"
        class="vod_nav_buttons"
        value="+{{ eSetup.vod_pageSize }}"
        (click)="handlePageChange('plus')"
      />
    </div>
    <div>
      <span class="hr_questions_total">
        <span
          *ngIf="
            !eSetup.questionOrChapter || eSetup.questionOrChapter === undefined
          "
          >{{ eLangGlob.translateQuestions }}
        </span>
        <span *ngIf="eSetup.questionOrChapter"
          >{{ eLangGlob.translateChapter }}
        </span>
        {{ eLangGlob.translateTotal }}: {{ count }}
      </span>
    </div>
  </div>
  <div
    class="ui middle aligned selection list hr_vod_box"
    *ngIf="screenwidth > 512; else vodBoxMobile"
  >
    <lse-vod-question-list-item
      (myEvent)="handleEvent($event)"
      (changeManualPageChangeFalse)="changeManualPageChangeFalse()"
      class="item"
      *ngFor="
        let q of questions
          | paginate
            : {
                itemsPerPage: pageSize,
                currentPage: page,
                totalItems: count
              }
      "
      [question]="q"
    ></lse-vod-question-list-item>

    <div *ngIf="!questions" class="ui active dimmer">
      <div class="ui large text loader">Loading...</div>
    </div>

    <p *ngIf="questions && !questions.length">
      There aren't any Questions yet :-(
    </p>
  </div>
  <ng-template #vodBoxMobile>
    <div
      class="question_wrapper"
      (swipeleft)="handlePageChange('plus')"
      (swiperight)="handlePageChange('minus')"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
        "
      >
        <div (click)="handlePageChange('minus')">
          <i class="ff arrow-left"></i>
        </div>

        <span>
          <span
            *ngIf="
              !eSetup.questionOrChapter ||
              eSetup.questionOrChapter === undefined
            "
            >{{ eLangGlob.translateQuestions }}
          </span>
          <span *ngIf="eSetup.questionOrChapter"
            >{{ eLangGlob.translateChapter }}
          </span>
          {{ page }}/{{ count }}</span
        >
        <div (click)="handlePageChange('plus')">
          <i class="ff arrow-right"></i>
        </div>
      </div>
      <lse-vod-question-list-item
        (myEvent)="handleEvent($event)"
        (changeManualPageChangeFalse)="changeManualPageChangeFalse()"
        class="item"
        *ngFor="
          let q of questions
            | paginate
              : {
                  itemsPerPage: pageSize,
                  currentPage: page,
                  totalItems: count
                }
        "
        [question]="q"
      ></lse-vod-question-list-item>

      <div *ngIf="!questions" class="ui active dimmer">
        <div class="ui large text loader">Loading...</div>
      </div>

      <p *ngIf="questions && !questions.length">
        There aren't any Questions yet :-(
      </p>
    </div>
  </ng-template>
</div>
<hr />
