import { Eventstatus } from '../shared/eventstatus';
import { Component, Input, OnInit, HostListener } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import {
 BaseChartDirective
} from 'ng2-charts';
import { TokenStorageService } from '../_services/token-storage.service';
import { StrawpollConfig } from '../shared/strawpoll-config';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { environment } from '../../environments/environment';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import {ViewChild} from '@angular/core';

@Component({
  selector: 'lse-user-strawpoll',
  templateUrl: './user-strawpoll.component.html',
  styleUrls: ['./user-strawpoll.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1.5s ease-in', style({ height: 0, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 0, opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          overflow: 'hidden',
          height: '*',
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out')),
    ]),
  ],
})
export class UserStrawpollComponent implements OnInit {
  constructor(
    private es: EventconfigStoreService,
    private ts: TokenStorageService
  ) {}

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() eStatus: Eventstatus;
  @Input() eLangGlob: any;

  form: any = {};
  isSuccessful = false;
  voteFailed = false;
  errorMessage = '';
  wait = true;
  StrawpollVote: any;
  StrawpollVotingData: any[];
  StrawpollResult: any[];
  vConfig: StrawpollConfig;
  hasStrawpollVoted = false;
  hasStrawpollVotedFor: number;
  screenwidth = document.body.offsetWidth;
  showStrawpoll = 'in';

  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 4,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          color: '#58585a',
          font: {
          },
          // fontSize: environment.fontSize,
        },
      },
    },
  };

  public pieChartLabels: string[];
  public pieChartData;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors = [
    {
      backgroundColor: environment.backgroundColorOptions,
    },
  ];

  ngOnInit(): void {
    if (this.ts.getHasStrawpollVoted() === true) {
      setTimeout(() => {
        this.wait = false;
      }, 500);
    }
    this.getStrawpollVotingConfig();
    this.hasStrawpollVoted = this.ts.getHasStrawpollVoted();
    if (this.hasStrawpollVoted) {
      this.hasStrawpollVotedFor = +this.ts.getStrawpollVoteResult();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
  }

  createRange(countOptions: number) {
    return new Array(countOptions);
  }

  // fontSize(): number {
  //   if (this.screenwidth < 512) {
  //     return 0.6 * environment.fontSize;
  //   }
  //   return environment.fontSize;
  // }

  onSubmit() {
    if (this.StrawpollVote !== undefined) {
      this.es.sendStrawpollVote(this.StrawpollVote).subscribe((data) => {
        this.wait = false;
        this.isSuccessful = true;
        this.voteFailed = false;
        this.ts.saveStrawpollVote(this.StrawpollVote);
        this.hasStrawpollVoted = true;
        this.hasStrawpollVotedFor = this.StrawpollVote;
        this.getStrawpollResult();
      });
    } else {
      this.voteFailed = true;
    }
  }

  getStrawpollVotingConfig() {
    this.es.getStrawpollVotingConfig(this.es.getActiveLang()).subscribe((e) => {
      this.vConfig = e;
      this.pieChartLabels = this.vConfig.options;
      this.getStrawpollResult();
    });
  }
  getStrawpollResult() {
    setInterval(() => {
      this.es.getStrawpollResult().subscribe((e) => {
        this.StrawpollResult = e;
        this.calculateResult();
      });
    }, 5000);
  }
  calculateResult() {
    this.StrawpollVotingData = [];
    // tslint:disable-next-line: forin
    for (const index in this.StrawpollResult) {
      this.StrawpollVotingData.push(this.StrawpollResult[index]);
    }
    this.pieChartData = this.StrawpollVotingData;
  }

  toggleStrawpoll() {
    this.showStrawpoll = this.showStrawpoll === 'out' ? 'in' : 'out';
  }
}
