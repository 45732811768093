import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { Question } from './question';
import { environment } from '../../environments/environment';

new HttpHeaders({ 'Content-Type': 'application/json' });
@Injectable({
  providedIn: 'root'
})
export class QuestionStoreService {

  private api = environment.apiUrl + 'api';

  constructor(private http: HttpClient, private logger: LogService,) { }

  /**
   * This is a workaround for testing.
   * POST is not working from localhost. Deactivate when live.
   */

   /**
  sendMsg(message): Observable<any>{
    this.logger.log('Message' + message.msgText);
    return this.http.get(`${this.api}/api.php?x=send_msg_get&mess_author=${message.author}&mess_firm=${message.location}&mess_txt=${message.msgText}`);
  }
  */
  getAllPagination(): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.api}/api.php?x=get_msg_vod`)
      .pipe(
        retry(3),
        catchError(this.errorHandler)
      );
  }
  getAllPaginationNew(): Observable<any>{
    return this.http.post(environment.apiUrl + `/mongodb/questions/getQuestionsVoD`,{});
  }
  getAllUAQ(limit: number, page: number): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.api}/api.php?x=get_msg_uaq&limit=${limit}&page=${page}`)
      .pipe(
        retry(3),
        catchError(this.errorHandler)
      );
  }
  getUAQCount(): Observable<number> {
    return this.http.get<number>(`${this.api}/api.php?x=get_msg_uaq_count`)
      .pipe(
        retry(3),
        catchError(this.errorHandler)
      );
  }
  getVodCount(): Observable<number> {
    return this.http.get<number>(`${this.api}/api.php?x=get_msg_vod_count`)
      .pipe(
        retry(3),
        catchError(this.errorHandler)
      );
  }
  private errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error('Fehler aufgetreten!');
    return throwError(error);
  }

  sendMsg(message): Observable<any>{
    this.logger.log('Message' + message.msgText);
    return this.http.post(this.api + '/api.php?x=send_msg', {
      mess_author : message.author,
      mess_firm : message.location,
      mess_txt : message.msgText
    });
  }
  sendMsgNew(message): Observable<any>{
    return this.http.post<any>(environment.apiUrl + '/mongodb/questions/newQuestion', {
      customer: environment.customerShort,
      mess_text: message.msgText,
      mess_author : message.author,
      mess_location : message.location,
    });
  }
  voteUAQ(id): Observable<any>{
    return this.http.post(this.api + '/api.php?x=voteUAQ', {
      mess_id : id
    });
  }
  saveVotes(id){
    let votes = [];
    this.logger.log('saveVotes ' + id);
    if (JSON.parse(localStorage.getItem('votes')) !== null){
      votes = JSON.parse(localStorage.getItem('votes'));
    }
    this.logger.log('saveVotes ' + votes);
    votes.push(id);
    localStorage.setItem('votes', JSON.stringify(votes));
    return votes;
  }
  getVotes(){
    return JSON.parse(localStorage.getItem('votes'));
  }
}
