import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { PlayerStoreService } from '../shared/player-store.service';
import { Question } from '../shared/question';
import videojs from 'video.js';

@Component({
  selector: 'lse-vod-question-list-item',
  templateUrl: './vod-question-list-item.component.html',
  styleUrls: ['./vod-question-list-item.component.css'],
})
export class VodQuestionListItemComponent implements OnInit {
  @Input() question: Question;
  @Output() myEvent = new EventEmitter();
  @Output() changeManualPageChangeFalse = new EventEmitter();

  questionTxt: string;
  screenwidth = document.body.offsetWidth;
  tcStart: number;
  tcEnd: number;
  highlight = false;
  player;
  constructor(
    private es: EventconfigStoreService,
    private vs: PlayerStoreService
  ) {}

  ngOnInit(): void {
    this.tcStart = this.getTcSecond(this.question.tc_start);
    this.tcEnd = this.getTcSecond(this.question.tc_end);
    this.questionTxt = this.question.txt_raw;
    if (this.es.getActiveLang() === 'de') {
      this.questionTxt = this.question.txt_de;

    } else if (this.es.getActiveLang() === 'en') {
      this.questionTxt = this.question.txt_en;
    }
    else if (this.es.getActiveLang() === 'nl') {
      this.questionTxt = this.question.txt_nl;
    }
    else if (this.es.getActiveLang() === 'pt') {
      this.questionTxt = this.question.txt_pt;
    }
    else if (this.es.getActiveLang() === 'es') {
      this.questionTxt = this.question.txt_es;
    }
    else if (this.es.getActiveLang() === 'cn') {
      this.questionTxt = this.question.txt_cn;
    }
    else if (this.es.getActiveLang() === 'jp') {
      this.questionTxt = this.question.txt_jp;
    }
    else {
      this.questionTxt = this.question.txt_raw;
    }
    this.highlightQuestion();
    this.player = this.vs.getPlayer();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
  }
  clickQuestion() {
    let a = this.question.tc_start.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    this.myEvent.emit(seconds);
    setTimeout(()=>{
      this.changeManualPageChangeFalse.emit();
  }, 1000);
  }
  highlightQuestion() {
    setInterval(() => {
      let playTime = Math.round(this.vs.getCurrentPlayTime());
      if (playTime >= this.tcStart && playTime < this.tcEnd) {
        this.highlight = true;
        // console.log('if ' + playTime + ' ' + this.tcEnd);
      } else {
        this.highlight = false;
        // console.log('else ' + playTime + ' ' + this.tcEnd);
      }
    }, 500);
  }
  getTcSecond(tc: string): number {
    let hms = tc;
    let a = hms.split(':');
    let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    return seconds;
  }
}
